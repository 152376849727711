import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Asset } from '../../interfaces/asset';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { AssetComponent } from '../asset/asset.component';
import { AssetService } from '../../services/asset.service';

import { ConnectorService } from '../../services/connector.service';
import {
  ClrAccordionModule,
  ClrAlertModule,
  ClrCheckboxModule,
  ClrInputModule,
  ClrModalModule,
} from '@clr/angular';
import { BehaviorSubject, filter } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { AnalyticsService } from '../../services/analytics.service';
import { ClrTextareaModule } from '@clr/angular';

import { DatabaseService } from '../../services/database.service';
import { SkeletonComponent } from '../skeleton/skeleton.component';
import {
  LoaderService,
  LoaderState,
  LoadingInformation,
} from '../../services/loader.service';
import { NetworkErrorResponse } from '../../interceptors/http.interceptor';

import { CategoryService } from '../../services/category.service';
import { Category } from '../../interfaces/category';

import { ClarityIcons, filter2Icon, filterOffIcon } from '@cds/core/icon';
import { isDateOlderThanLimit } from '../../services/useables';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-asset-wrapper',
  standalone: true,
  imports: [
    NgForOf,
    AssetComponent,
    ClrAlertModule,
    NgIf,
    NgClass,
    ClrInputModule,
    ClrCheckboxModule,
    FormsModule,
    ClrModalModule,
    ClrTextareaModule,
    ClrAccordionModule,
    SkeletonComponent,
    SnackbarComponent,
  ],
  templateUrl: './asset-wrapper.component.html',
  styleUrl: './asset-wrapper.component.css',
})
export class AssetWrapperComponent implements OnInit {
  selectedCategoryNumber: number = 0;
  selectedCategoryName: string = '';
  assets: Asset[] = [];
  filteredAssets: Asset[] = [];
  isLoading = false;
  isFetchingSingle = false;
  fetchedSingleID = -1;
  dataFetchingChunkSize = 5;
  editMode = false;
  openEditModal = false;
  selectedAsset: Asset | null = null;
  error: boolean = false;
  errorMessage = '';

  autoUpdateOn = true;
  dataNeedUpdate = false;
  cachedAssets: Asset[] = [];
  appState: { loadingData: LoadingInformation; state: LoaderState } = {
    loadingData: { message: '' },
    state: LoaderState.NotLoading,
  };
  moreAssetsExist = false;

  showMoreIconsFilters = false;
  //hold number of assets in local db for category
  totalLocalAssetsInCatagroy = 0;

  //categories with sub categories, used for filtering, comes directly from API
  categoriesWithChildren: Category[] = [];

  //all data arrays for filtering icons
  allCategoriesActivated: boolean = true;

  //filtered sub Categories of current Route
  //used in Angular html template
  availableChildCategories: Category[] = [];

  //selected Categories by User for Filtering
  selectedCategories: Category[] = [];
  //Ids of selected Categories by User, used for id Mapping with main List
  selectedFilterCategoriesIds: number[] = [];
  //last time category been updated
  lastUpdatedDate: Date | null = null;
  childrenCategoriesIds: number[] = [];
  cumulatedAssets: Array<{
    subCategoryId: number;
    assets: Asset[];
    inSelectedList: boolean;
  }> = [];
  requireLogoutObserable: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  requireLogout = this.requireLogoutObserable.asObservable();

  //since get Api Assets need to be informed if user routed into new category
  //this State inform service if fetchin need to be interrupted caused by category switch
  private currentCycle = new BehaviorSubject<number>(0);
  showSnackbar = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private connectorService: ConnectorService,
    private analyticsService: AnalyticsService,
    private assetService: AssetService,
    private categoryService: CategoryService,
    private dbService: DatabaseService,
    private loaderService: LoaderService
  ) {
    ClarityIcons.addIcons(filter2Icon, filterOffIcon);
  }

  async ngOnInit() {
    await this.dbService.getDatabase();

    this.activatedRoute.params.subscribe(async (params) => {
      //Setting currentCycle and SelectedCategory first in new route is essential!!
      //Please dont change order of this
      this.currentCycle.next(this.currentCycle.getValue() + 1);
      //this Date is always being persisted for the Parent Category, so if one has children, then
      //this date indicate the update date of all children
      this.lastUpdatedDate = await this.dbService.getCategoryLastUpdatedDate(
        this.selectedCategoryNumber
      );

      this.selectedCategoryNumber = params['categoryID'];
      this.selectedCategoryName = params['categoryName'];
      this.selectedCategories = [];
      this.selectedFilterCategoriesIds = [];
      this.categoryService.categoriesWithChildrenObserve.subscribe((cats) => {
        this.categoriesWithChildren = cats;
      });

      if (this.categoriesWithChildren.length === 0) {
        this.categoriesWithChildren =
          await this.dbService.getAllCategoriesRecordsLocally();
      }

      //Todo: Check if this is necessary
      //Not Safe way to check falsy and nully
      //if necessary, build typeGuard
      this.availableChildCategories =
        (
          this.categoriesWithChildren.filter((e) => {
            return (
              e.children !== undefined &&
              e.id === Number(this.selectedCategoryNumber)
            );
          })[0] ?? []
        ).children! ?? [];

      this.childrenCategoriesIds =
        this.availableChildCategories.length !== 0
          ? this.availableChildCategories.map((e) => e.id)
          : [];

      //fetch number of localdata exists
      this.totalLocalAssetsInCatagroy =
        await this.dbService.getAssetCountByCategory(params['categoryID']);

      //enable or disable LoadMoreButton
      this.moreAssetsExist =
        this.totalLocalAssetsInCatagroy > 500 &&
        this.selectedCategories.length === 0;
      //Necessary to persist DataUpdate (in App between renders and session)
      //Type selected by User Between Renders
      this.assetService.dataSynchronizingTypeState$.subscribe(
        (next) => (this.autoUpdateOn = next)
      );
      this.loaderService.loadingState.subscribe((lS) => (this.appState = lS));
      await this.intitializeComponent();
    });
  }

  /**
   *
   * @param refreshCall If Its Syncronize Event Based Call , then checking Local DB can be skipped
   * @returns True if Job Success otherwise false
   */
  async intitializeComponent(refreshCall?: boolean) {
    this.analyticsService.trackEvent(
      'Category selected',
      `Category ${this.selectedCategoryNumber}`,
      'SELECT_CATEGORY'
    );
    this.error = false;
    this.assets = [];
    this.filteredAssets = [];

    //if category has no assets in local db, then fetch data from API
    //and its not manually triggered by user
    if (!refreshCall && this.totalLocalAssetsInCatagroy !== 0) {
      await this.getLocalData();
    } else {
      try {
        await this.getApiAssetsByFetchType('allAssets');
        // This Else Section initialize the App with data for first time
        // it runs if app being oppended first time or user logging after long period
        if (
          this.totalLocalAssetsInCatagroy === 0 &&
          !refreshCall &&
          this.assets.length !== 0
        ) {
          console.warn('DATA IS BEING INITIALIZED LOCALLY FROM API');
          await this.dbService.addRecords('assets', this.assets);
        }
        // This Section refresh local data with API Data
        else if (this.assets.length !== 0) {
          const resetLocalCategory =
            await this.dbService.deleteAssetsByCategory(
              this.selectedCategoryNumber
            );
          if (resetLocalCategory) {
            await this.dbService.addRecords('assets', this.assets);
            // this.initializing = false
          }
        }
      } catch (error) {
        if (
          (error as Error).cause &&
          ((error as Error).cause as NetworkErrorResponse).errorName ===
            'HttpErrorResponse'
        ) {
          this.error = true;
          this.errorMessage =
            'Bitte stellen Sie sicher, dass Sie mit dem Netzwerk verbunden sind.';
        }
        console.error(
          refreshCall ? "Could't refresh," : 'Get Api Assets Got Rejected,',
          ' Caused By: ',
          (error as Error).message
        );
      }
    }
  }
  /**
   * Function to get Local Data from IndexedDB
   * If autoUpdate is enabled, then it will check if new data is available
   * @returns Nothing but propegates the call to runAutoUpdateAssets
   */
  async getLocalData() {
    const localAssetsDataSet =
      await this.dbService.getDataFromIndexDbByCategory(
        this.selectedCategoryNumber
      );
    //if local data exists, then initialize component with local data
    if (localAssetsDataSet && localAssetsDataSet.length > 0) {
      console.warn('DATA IS BEING INITIALIZED LOCALLY');
      this.assets = [...localAssetsDataSet];
      this.filteredAssets = [...localAssetsDataSet];
      if (this.autoUpdateOn) {
        await this.runAutoUpdateAssets();
      }
    }
  }
  /**
   * Check for new Data differ between last updated date and current date
   * also if API has different number of assets than local db
   * @returns Nothing but propegates the call to refreshAssets if new data is available
   * or getApiAssetsByFetchType if last update date exceeded 7 days
   */
  async runAutoUpdateAssets() {
    //fetch data from API and compare with local data
    try {
      let countApiAssets: number = 0;
      let countLocalAssets = 0;

      // category has children
      if (this.availableChildCategories.length !== 0) {
        // next built in arrayReduce Call fetch for each childCategory the number of assets
        // then sum them up to get the total number of assets in local db
        // that way we could know how many assts parent category has
        countLocalAssets = await this.childrenCategoriesIds.reduce(
          async (acc, id) => {
            const previousCount = await acc;
            const currentCount = await this.dbService.getAssetCountByCategory(
              this.selectedCategoryNumber,
              id
            );
            return previousCount + currentCount;
          },
          Promise.resolve(0)
        );
        // Api Call , get child number of assets
        countApiAssets = (
          await this.assetService.getCountChildAssets(
            this.selectedCategoryNumber
          )
        ).assetsNumber;
      } else {
        countApiAssets = (
          await this.assetService.getCountAssets(this.selectedCategoryNumber)
        ).assetsNumber;
        countLocalAssets = this.totalLocalAssetsInCatagroy;
      }

      if (countApiAssets !== countLocalAssets) {
        console.warn('NEW DATA BEEN DETECTED, AN UPLOAD WILL TAKE A PLACE.');
        await this.refreshAssets();
      }

      const lastUpdatedLimitExceeded = isDateOlderThanLimit(
        this.lastUpdatedDate?.toISOString() ?? '',
        7
      );
      if (lastUpdatedLimitExceeded) {
        console.warn('DATA BEEN EXCEEDED 7 DAYS LIMIT');
        await this.getApiAssetsByFetchType('newAssets');
      }
    } catch (error) {
      if (
        (error as Error).cause &&
        ((error as Error).cause as NetworkErrorResponse).errorName ===
          'HttpErrorResponse'
      ) {
        this.error = true;
        this.errorMessage =
          'Bitte stellen Sie sicher, dass Sie mit dem Netzwerk verbunden sind.';
      }
      console.error('Get Api Assets Got Rejected!:', (error as Error).message);
    }
  }
  /**
   *
   * @param fethType indicates if Api Assets should be fetched by newAssets or allAssets
   * newAssets fetches only new data from API by newData Endpoint
   * allAssets fetches all data from API by allData Endpoint for a category
   */
  async getApiAssetsByFetchType(fethType: 'newAssets' | 'allAssets') {
    this.showSnackbar = true;
    if (this.childrenCategoriesIds.length !== 0) {
      console.warn('FILTERABLE CATEGORY DETECTED', ', SUB CATEGORIES DETECTED');
      for (let child of this.childrenCategoriesIds) {
        const assets = await this.assetService.getApiAssetsByCategorie(
          this.currentCycle,
          child,
          this.dataFetchingChunkSize,
          fethType === 'newAssets'
            ? this.assetService.getNewAssets
            : this.assetService.getAssets,
          this.assets,
          this.filteredAssets,
          true,
          Number(this.selectedCategoryNumber)
        );
        if (assets.length > 0 && fethType === 'newAssets') {
          this.refreshAssets();
        }
      }
      return this.assets.length;
    } else {
      const assets = await this.assetService.getApiAssetsByCategorie(
        this.currentCycle,
        this.selectedCategoryNumber,
        this.dataFetchingChunkSize,
        fethType === 'newAssets'
          ? this.assetService.getNewAssets
          : this.assetService.getAssets,
        this.assets,
        this.filteredAssets
      );
      if (assets.length > 0 && fethType === 'newAssets') {
        this.refreshAssets();
      }
      return assets.length;
    }
  }
  async refreshAssets() {
    this.moreAssetsExist = false;
    await this.intitializeComponent(true);
  }
  /**
   * Clicked whenever user need to cancel refresh request
   * by reloading all fetch operations will be aborted automatically
   */
  async cancelRefreshAssets() {
    location.reload();
  }
  async assetClicked(asset: Asset) {
    if (this.editMode) {
      // TODO: Open edit panel
      this.selectedAsset = asset;
      this.openEditModal = true;
      return;
    }
    if (this.isFetchingSingle) {
      return;
    }
    this.isFetchingSingle = true;
    this.fetchedSingleID = asset.id;
    const single = await this.assetService.getSingle(asset.id);
    this.isFetchingSingle = false;
    this.fetchedSingleID = -1;
    if (single.length != 1) {
      // TODO: Notification an den User schicken
      return;
    }
    this.connectorService.addAsset(single[0]);
  }

  async filterAssets(event: Event) {
    const target = event.target as HTMLInputElement;
    this.filteredAssets = this.assets.filter((item) => {
      return item.title
        .toLowerCase()
        .replaceAll(' ', '')
        .includes(target.value.toLowerCase().replaceAll(' ', ''));
    });
  }

  /**
   * as long as subtracting totalAssets founded in local db from assets already been rendered positive
   * then this callback can be executed to load more elements into assets cluster
   */
  async handleLoadMoreAssets(filterSet?: number[], filterCall?: boolean) {
    if (this.moreAssetsExist) {
      const newAssets = await this.dbService.getDataFromIndexDbByCategory(
        this.selectedCategoryNumber,
        300,
        this.assets.length
      );
      this.assets = [...this.assets, ...newAssets];
      this.filteredAssets = [...this.assets];
      if (!filterCall) {
        this.moreAssetsExist =
          this.totalLocalAssetsInCatagroy - this.assets.length > 0;
      }
    }
  }
  /**
   * Method get Ids Of Sub Categories for next logic Filter Assets Method to work with
   * @param category new Sub Category to be filtered
   * @param emtpySelection User cleared all filters
   * @returns Nothing but propegates the call to filterAssetsBySelectedCategories
   */
  async onFilterCategoryClicked(category?: Category, emtpySelection?: boolean) {
    if (emtpySelection) {
      this.selectedCategories = [];
      this.filteredAssets = this.assets;
      return;
    }
    if (category && this.selectedCategories.includes(category)) {
      this.selectedCategories = this.selectedCategories.filter(
        (cat) => cat !== category
      );
    } else {
      this.selectedCategories.push(category!);
    }
    this.filterAssetsBySelectedCategories();
  }
  async filterAssetsBySelectedCategories() {
    if (this.selectedCategories.length === 0) {
      this.filteredAssets = this.assets;
      return;
    } else {
      this.selectedFilterCategoriesIds = this.selectedCategories.map(
        (category) => category.id
      );
      if (this.cumulatedAssets.length !== 0) {
        this.selectedFilterCategoriesIds =
          this.selectedFilterCategoriesIds.filter(
            (id) =>
              !this.cumulatedAssets.some((item) => item.subCategoryId === id)
          );
      }
      if (this.selectedFilterCategoriesIds.length > 0) {
        let lodaingLocally = this.loaderService.show(
          { message: 'Filtering Assets...' },
          LoaderState.LocalLoading
        );
        const assetPromises = this.selectedFilterCategoriesIds.map((e) =>
          this.dbService.getAssetsByCategoryAndChildCategorie(
            this.selectedCategoryNumber,
            e
          )
        );
        const assetsArray = await Promise.all(assetPromises);
        this.cumulatedAssets = [
          ...this.cumulatedAssets,
          ...assetsArray.map((assets, index) => ({
            assets: assets,
            inSelectedList: true,
            subCategoryId: this.selectedFilterCategoriesIds[index],
          })),
        ];
        this.loaderService.hide(lodaingLocally);
      }

      this.filteredAssets = this.cumulatedAssets
        .map((e) => e.assets)
        .flat()
        .filter((asset) => {
          return this.selectedCategories.some(
            (cat) => cat.id === asset.childCategory
          );
        });
    }
  }
  toggleShowIconsFilters() {
    this.showMoreIconsFilters = !this.showMoreIconsFilters;
  }

  protected readonly filter = filter;
}
