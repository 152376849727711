import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, from, mergeMap, throwError } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../enviroments/environment';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService) {}

  token: string = '';

  /**
   * Intercepts HTTP requests to the own backend and adds an access token to
   * the header before making the request.
   * @param request the request that has to be intercepted
   * @param next handler for next request step
   * @returns an observable with the http response from the server
   */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Update service

    if (!this.msalService.instance.getActiveAccount()) {
      console.error('USER NOT FOUND');
      throw Error('No logged in user!');
    }

    const httpHeader = {
      Authorization: '',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    // Get access token from azure with own api scope
    return from(
      this.msalService.instance.acquireTokenSilent({
        scopes: environment.apiConfig.scopes,
      })
    ).pipe(
      mergeMap((data) => {
        const accessToken = data.idToken;
        // add access token
        httpHeader.Authorization = `Bearer ${accessToken}`;
        const req = request.clone({ setHeaders: httpHeader });
        return next.handle(req);
      }),
      catchError((err) => {
        if (err instanceof InteractionRequiredAuthError) {
          this.msalService.loginRedirect();
          return throwError(
            () => new Error('Need to sign in to continue: ' + err.message)
          );
        }
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.msalService.loginRedirect();
            return throwError(
              () => new Error('Unauthorized. Redirecting to login...')
            );
          } else {
            return throwError(
              () =>
                new Error('HTTP Error: ' + err.message, {
                  cause: {
                    errorName: err.name,
                    status: err.status,
                    message: err.message,
                  },
                })
            );
          }
        }
        return throwError(
          () => new Error('An unexpected error occurred: ' + err.message)
        );
      })
    );
  }
}

export type NetworkErrorResponse = {
  status: number;
  errorName: string;
  message: string;
};
