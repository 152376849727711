import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AssetService } from '../services/asset.service';
import { LoaderService, LoaderState } from '../services/loader.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(
    private dataService: AssetService,
    private loaderService: LoaderService
  ) {}
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let id = this.loaderService.show(
      { url: req.url, message: 'Daten werden abgerufen...' },
      LoaderState.HttpLoading
    );
    return next.handle(req).pipe(
      finalize(() => {
        this.loaderService.hide(id);
      })
    );
  }
}
