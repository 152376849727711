import { Component, Input } from '@angular/core';
import { Asset } from '../../interfaces/asset';
import { NgClass, NgIf } from '@angular/common';

import { ClrModalModule } from '@clr/angular';
import { ClarityIcons, trashIcon } from '@cds/core/icon';

@Component({
  selector: 'app-asset',
  standalone: true,
  imports: [NgIf, NgClass, ClrModalModule],
  templateUrl: './asset.component.html',
  styleUrl: './asset.component.css',
})
export class AssetComponent {
  constructor() {
    ClarityIcons.addIcons(trashIcon);
  }
  @Input('asset') asset: Asset | undefined;
  @Input('showTitle') showTitle: boolean = false;
  @Input('editMode') editMode: boolean = false;
  @Input('categoryName') cagtegoryName: string = '';
}
