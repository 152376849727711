import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Category } from '../interfaces/category';
import { environment } from '../../enviroments/environment';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  categoriesWithChildren: BehaviorSubject<Category[]> = new BehaviorSubject<
    Category[]
  >([]);
  categoriesWithChildrenObserve = this.categoriesWithChildren.asObservable();
  constructor(private http: HttpClient) {}

  async getCategories() {
    const response = await lastValueFrom(
      this.http.get<Category[]>(
        `${environment.appConfig.apiEndpoint}/categories`
      )
    );

    let categories: Category[] = [];

    for (const cat of response) {
      if (cat.parent == null || cat.parent === 0) {
        let children = await lastValueFrom(
          this.http.get<Category[]>(
            `${environment.appConfig.apiEndpoint}/categories/childs?category=${cat.id}`
          )
        );
        if (children.length > 0) {
          cat.children = children;
        }
        categories.push(cat);
      }
    }
    this.categoriesWithChildren.next(
      categories.filter((cat) => cat.children !== undefined)
    );
    return categories;
  }
}
