import { Injectable } from '@angular/core';
import { Asset } from '../interfaces/asset';
import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectorService {
  private numberOfAssets = 0;
  constructor(private analyticsService: AnalyticsService) {}
  addAsset(asset: Asset) {
    // @ts-ignore
    console.log(Office.context.ui);
    this.analyticsService.trackEvent(
      `Asset added`,
      `${asset.title} from ${asset.category} added`,
      'ADD_ASSET'
    );
    // @ts-ignore
    Office.context.ui.messageParent(
      JSON.stringify({
        content: asset.encoded_data,
        type: asset.type,
      })
    );
  }
}
