import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {

  private _loadingState: BehaviorSubject<{
    loadingData: LoadingInformation;
    state: LoaderState;
  }> = new BehaviorSubject<{
    loadingData: LoadingInformation;
    state: LoaderState;
  }>({ loadingData: { message: '' }, state: LoaderState.NotLoading });

  private data: LoadingInformation[] = [];

  public loadingState: Observable<{
    loadingData: LoadingInformation;
    state: LoaderState;
  }> = this._loadingState.asObservable();

  constructor() {}

  /**
   * Add new loading data to stack. Returns the id
   * @param data
   */
  show(data: LoadingInformation, loaderState: LoaderState) {
    data.id = self.crypto.randomUUID();

    this.data.push(data);
    this._loadingState.next({ loadingData: data, state: loaderState });
    return data.id;
  }

  /**
   * Remove a loader info dataset from the stack by given id
   * @param id
   */
  hide(id: string) {
    this.data = this.data.filter((item) => item.id !== id);
    if (this.data.length === 0) {
      this._loadingState.next({
        loadingData: { message: '' },
        state: LoaderState.NotLoading,
      });
    }
  }

  /**
   * Returns the current loading information set
   */
  getData() {
    return this.data;
  }
}

export interface LoadingInformation {
  id?: string;
  url?: string;
  message: string;
}

export enum LoaderState {
  HttpLoading = 'HttpLoading',
  NotLoading = 'NotLoading',
  LocalLoading = 'LocalLoading',
  TransactionStarted ='TransactionStarted'
}
