<div
  id="skeleton_loader"
  *ngIf="readingData"
  class="skeleton-container"
>
  <div
    *ngFor="let _ of [].constructor(50); let i = index"
    class="skeleton-item"
  >
    <div class="skeleton-image"></div>
    <div class="skeleton-text"></div>
  </div>
</div>
