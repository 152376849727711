import { Asset } from '../interfaces/asset';

/**
 * function to convert MS to Min:SS
 * @param milliseconds Time passed in MS
 * @returns
 */
export const millisecondsToMinutesSeconds = (milliseconds: number) => {
  const totalSeconds = Math.round(milliseconds / 1000);
  const minutes = Math.round(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${minutes}m ${seconds}s`;
};

/**
 *
 * @param originalArray the array before modification
 * @param updatedArray  the array contains possible new data
 * @returns either the original Array or one modificated with new data
 */
export const synchronizeData = (
  originalArray: Asset[],
  updatedArray: Asset[]
): unknown[] => {
  let originalStringArray = originalArray.map((e) => JSON.stringify(e));
  let updatedStringArray = updatedArray.map((e) => JSON.stringify(e));
  let filteredData: Array<Asset> = [];
  const originalSet = new Set(originalStringArray);
  const updatedSet = new Set(updatedStringArray);
  const remoteToLocal = updatedArray.filter((item) => {
    return !originalSet.has(JSON.stringify(item));
  });
  const LocalToRemote = originalArray.filter((item) => {
    return !updatedSet.has(JSON.stringify(item));
  });
  if (LocalToRemote.length > 0) {
    filteredData = originalArray.filter((o) => !LocalToRemote.includes(o));
  }
  if (remoteToLocal.length > 0) {
    originalArray.push(...remoteToLocal);
    filteredData = originalArray;
  }
  return filteredData;
};
export const isDateOlderThanLimit = (dateString: string, limit: number) => {
  const dateToCheck = new Date(dateString);
  const today = new Date();
  const diffInMs = today.getTime() - dateToCheck.getTime();
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  return diffInDays > limit;
};
