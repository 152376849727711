<div
  *ngIf="asset"
  [class]="cagtegoryName === 'Icons' ? 'icons-card' : 'card'"
  [ngClass]="{ scale: !editMode }"
>
  <div id="card-image" class="card-img">
    <img
      id="asset-image"
      src="data:image/png;base64,{{ asset.thumbnail }}"
      style="max-width: 100%"
    />
  </div>

  <div class="card-block" *ngIf="showTitle" id="card-block">
    <p class="card-text" id="card-text">
      {{
        cagtegoryName === "Icons"
          ? asset.title.split("_")![asset.title.split("_").length! - 1]
          : asset.title
      }}
    </p>
  </div>
</div>
