import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../enviroments/environment';
import { Asset } from '../interfaces/asset';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  // Indicate that a Http Request is on going so loader indicator can be activated
  private apiRequestIsLoading = new BehaviorSubject<{
    state: boolean;
    msg: string;
  }>({ state: false, msg: '' });
  isLoading$ = this.apiRequestIsLoading.asObservable();
  //____________________________________________________________________________

  // Define the mode the user working with, if this is false, then data wont be updated automatically
  private dataSynchronizingTypeState = new BehaviorSubject<boolean>(false);
  dataSynchronizingTypeState$ = this.dataSynchronizingTypeState.asObservable();

  setDataSynchronizingUpdateState(newState: boolean) {
    this.dataSynchronizingTypeState.next(newState);
  }
  //_____________________________________________________________________________

  //State helps as meta data for user, how many assets already been fetched
  private countedAssets = new BehaviorSubject<number>(0);
  assetsCountLoaded$ = this.countedAssets.asObservable();
  //_____________________________________________________________________________

  //State helps to interrupt fetchin Assets if User wish to
  private httpRequestInterrupt = new BehaviorSubject<boolean>(false);
  httpRequestInterrupt$ = this.httpRequestInterrupt.asObservable();
  setHttpRequestInterrupt = (newValue: boolean) =>
    this.httpRequestInterrupt.next(newValue);
  //______________________________________________________________________________________

  db: IDBDatabase | undefined;

  constructor(private http: HttpClient) {}

  getAssets(
    category: number,
    offset: number,
    limit: number,
    httpInst: HttpClient
  ): Promise<Asset[]> {
    return lastValueFrom(
      httpInst.get<Asset[]>(
        `${environment.appConfig.apiEndpoint}/assets?category=${category}&offset=${offset}&limit=${limit}`
      )
    );
  }
  getNewAssets(
    category: number,
    offset: number,
    limit: number,
    httpInst: HttpClient
  ): Promise<Asset[]> {
    return lastValueFrom(
      httpInst.get<Asset[]>(
        `${environment.appConfig.apiEndpoint}/assets/new?category=${category}&offset=${offset}&limit=${limit}`
      )
    );
  }
  getChildsByParent(
    parentCategory: number,
    offset: number,
    limit: number
  ): Observable<Asset[]> {
    return this.http.get<Asset[]>(
      `${environment.appConfig.apiEndpoint}/assets/childs?category=${parentCategory}&offset=${offset}&limit=${limit}`
    );
  }
  getChildAssets(
    parentCategory: number,
    childCategory: number,
    offset: number,
    limit: number
  ): Observable<Asset[]> {
    return this.http.get<Asset[]>(
      `${environment.appConfig.apiEndpoint}/assets/childs/filtered?category=${parentCategory}&childCategory=${childCategory}&offset=${offset}&limit=${limit}`
    );
  }
  getSingle(id: number): Promise<Asset[]> {
    return lastValueFrom(
      this.http.get<Asset[]>(
        `${environment.appConfig.apiEndpoint}/singleAsset?id=${id}`
      )
    );
  }
  getCountAssets(parentCategory: number): Promise<{ assetsNumber: number }> {
    return lastValueFrom(
      this.http.get<{ assetsNumber: number }>(
        `${environment.appConfig.apiEndpoint}/assets/count?category=${parentCategory}`
      )
    );
  }
  getCountChildAssets(
    parentCategory: number
  ): Promise<{ assetsNumber: number }> {
    return lastValueFrom(
      this.http.get<{ assetsNumber: number }>(
        `${environment.appConfig.apiEndpoint}/assets/count/childs?category=${parentCategory}`
      )
    );
  }
  deleteAsset(parentCategory: number): Promise<{ assetsNumber: number }> {
    return lastValueFrom(
      this.http.get<{ assetsNumber: number }>(
        `${environment.appConfig.apiEndpoint}/assets/count/childs?category=${parentCategory}`
      )
    );
  }
  showLoader(msg: string) {
    this.apiRequestIsLoading.next({ state: true, msg: msg });
  }

  hideLoader() {
    this.apiRequestIsLoading.next({ state: false, msg: '' });
  }

  /**
   *
   * @param cycle Every cycle represenet an event driven execution cycle
   * the event been triggered by this function caller on every new route
   * of Router , mostly when user switch between assets categories of Panel
   * ------------------------------------------------------------------------
   * @param currentCycle the current cylce , so route which differ from cycle if user switched route ,
   * this assure that no assets been added to the end list between loads
   * --------------------------------------------------------------------
   * @param selectedCategory assets categorie to load
   *
   * @param assets the assets list to fill when data fetched successfully from API
   * @param filteredAssets filtered Assets
   * @returns Resolved Promise or Rejected if Error occured
   */
  async getApiAssetsByCategorie(
    currentCycleObserv: BehaviorSubject<number>,
    selectedCategory: number,
    dataChunkSize: number,
    getterFunction: (
      category: number,
      offset: number,
      limit: number,
      http: HttpClient
    ) => Promise<Asset[]>,
    assets?: Asset[],
    filteredAssets?: Asset[],
    isChild?: boolean,
    parentCategory?: number
  ): Promise<Asset[]> {
    return new Promise(async (resolve, reject) => {
      const cycle = currentCycleObserv.getValue();
      const currentCycle$ = currentCycleObserv.asObservable();
      let currentCycle = currentCycleObserv.getValue();
      currentCycle$.subscribe((next) => (currentCycle = next));
      let offset = 0;
      let apiData: Promise<Asset[]>;
      let cumulatedData: Asset[] = [];
      this.countedAssets.next(0);
      do {
        apiData = getterFunction(
          selectedCategory,
          offset,
          dataChunkSize,
          this.http
        );
        // Keine Einträge zur Liste hinzufügen, wenn in der Zwischenzeit ein neuer Zyklus angefangen hat.
        if (currentCycle != cycle) {
          reject(new Error('HttpInterrupt, Category been switched'));
          break;
        }
        apiData.catch((reason) => reject(reason));
        if ((await apiData).length != 0) {
          const apiDataWithOrNoParentCategory: Asset[] =
            isChild && parentCategory
              ? (await apiData).map((asset) => ({
                  ...asset,
                  //here i am setting all childAssets to One Parent Category
                  category: parentCategory,
                  childCategory: selectedCategory,
                }))
              : await apiData;
          cumulatedData.push(...apiDataWithOrNoParentCategory);
          assets?.push(...apiDataWithOrNoParentCategory);
          filteredAssets?.push(...apiDataWithOrNoParentCategory);
        }
        offset += dataChunkSize;
        //followed line for testing
        // if (cumulatedData?.length === 1500) dataChunkSize = 3;
      } while ((await apiData).length == dataChunkSize);
      resolve(cumulatedData);
    });
  }
}
