/* eslint-disable @typescript-eslint/no-unused-vars */
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

// @ts-ignore
Office.onReady((info) => {
  console.log('App ready');
  if (location.hostname !== 'localhost') {
    navigator.serviceWorker
      .register('/assets/sw.js')
      .then((result) =>
        console.log('Service worker registration successfully.')
      )
      .catch((error) => console.error(error));
  }
  bootstrapApplication(AppComponent, appConfig).catch((err) =>
    console.error(err)
  );
});
