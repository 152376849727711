export const environment = {
  production: false,
  msalConfig: {
    auth: {
      clientId: 'c3e99e9d-188f-4035-aa48-8405514c78a3',
      authority:
        'https://login.microsoftonline.com/a853a335-e38e-436b-98e2-9ffabedf1e1e',
    },
  },
  apiConfig: {
    scopes: ['user.read'],
    uri: 'api://c3e99e9d-188f-4035-aa48-8405514c78a3/Test.Read',
  },
  appConfig: {
    apiEndpoint: 'https://cronos-powerslide-backend.azurewebsites.net/api',
    //apiEndpoint: 'http://localhost:7071/api',
  },
};
