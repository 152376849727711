<div [ngClass]="{ fetching: isFetchingSingle }">
  <div class="clr-row">
    <div class="clr-col">
      <h1>Verfügbare Elemente</h1>
      <button
        class="btn btn-outline inverse"
        style="margin-top: 1rem"
        (click)="refreshAssets()"
        *ngIf="
          this.appState.state !== 'TransactionStarted' &&
          this.appState.state === 'NotLoading' &&
          !autoUpdateOn
        "
      >
        Assets Aktualisieren<clr-icon shape="cloud"></clr-icon>
      </button>
      <div
        style="
          display: flex !important;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <button
          [disabled]="this.appState.state === 'TransactionStarted'"
          class="btn btn-danger-outline"
          style="margin-top: 1rem; max-width: min-content"
          type="button"
          colo
          (click)="cancelRefreshAssets()"
          *ngIf="
            !autoUpdateOn &&
            this.appState.state !== 'NotLoading' &&
            totalLocalAssetsInCatagroy !== 0
          "
        >
          Abbrechen
        </button>
        <h6
          style="
            font-size: 0.7rem;
            font-weight: bold;
            display: inline-flex;
            align-content: start;
          "
          *ngIf="appState.state === 'TransactionStarted'"
        >
          Bitte warten, Kategorie wird initialisiert ....
        </h6>
      </div>

      <clr-toggle-container style="display: none">
        <clr-toggle-wrapper>
          <input type="checkbox" clrToggle [(ngModel)]="editMode" />
          <label>Bearbeitungsmodus</label>
        </clr-toggle-wrapper>
      </clr-toggle-container>
    </div>
    <div
      class="clr-col-2"
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      "
    >
      <clr-input-container style="align-items: flex-end">
        <input
          [disabled]="isLoading"
          clrInput
          placeholder="Suchbegriff..."
          (keyup)="filterAssets($event)"
        />
      </clr-input-container>
      <button
        class="btn btn-icon"
        aria-label="settings"
        style="border-radius: 2rem; align-self: flex-end"
        (click)="toggleShowIconsFilters()"
        *ngIf="
          this.selectedCategoryName === 'Icons' &&
          this.appState.state === 'NotLoading'
        "
        type="button"
      >
        Filters
        <cds-icon
          [attr.shape]="
            showMoreIconsFilters ? 'filter-off' : 'filter-grid-circle'
          "
        ></cds-icon>
      </button>
    </div>
  </div>
  <div
    class="clr-col"
    *ngIf="
      selectedCategoryName === 'Icons' && this.appState.state === 'NotLoading'
    "
  >
    <button
      class="clear-all-button"
      (click)="onFilterCategoryClicked(undefined, true)"
      style="outline: none"
      *ngIf="this.selectedCategories.length > 0"
    >
      clear all filters
      <cds-icon shape="times"></cds-icon>
    </button>
    <div class="clr-row" style="gap: 0.4rem; margin: 1rem">
      <button
        *ngFor="
          let category of this.showMoreIconsFilters
            ? this.availableChildCategories
            : this.selectedCategories
        "
        class="item"
        [class.selected]="this.selectedCategories.includes(category)"
        (click)="onFilterCategoryClicked(category)"
        style="outline: none"
      >
        {{ category.name }}
        <clr-icon
          shape="times"
          style="margin: 0.2rem"
          *ngIf="this.selectedCategories.includes(category)"
        ></clr-icon>
      </button>
    </div>
  </div>
  <div class="clr-row">
    <app-skeleton
      [readingData]="appState.state === 'LocalLoading'"
    ></app-skeleton>
    <div
      [class]="
        selectedCategoryName === 'Icons'
          ? 'clr-col-sm-4 clr-col-md-2 '
          : 'clr-col-sm-4 clr-col-md-3'
      "
      *ngFor="let asset of filteredAssets"
    >
      <app-asset
        [asset]="asset"
        (click)="assetClicked(asset)"
        [showTitle]="true"
        [editMode]="editMode"
        *ngIf="assets.length > 0"
        categoryName="{{ selectedCategoryName }}"
      ></app-asset>
    </div>
    <div
      class="clr-col"
      *ngIf="
        this.appState.state === 'NotLoading' && assets.length == 0 && !error
      "
    >
      <clr-alert [clrAlertClosable]="false">
        <clr-alert-item>
          <span class="alert-text"
            >In dieser Kategorie sind leider keine Inhalte vorhanden. Wenn du zu
            neuen Inhalten beitragen möchtest, kontaktiere gerne direkt das
            Marketing.</span
          >
        </clr-alert-item>
      </clr-alert>
    </div>
    <div class="clr-col" *ngIf="error">
      <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <clr-alert-item>
          <span>{{ errorMessage }}</span>
        </clr-alert-item>
      </clr-alert>
    </div>
  </div>
  <div
    class="clr-row"
    *ngIf="moreAssetsExist && this.selectedCategories.length === 0"
  >
    <app-skeleton
      [readingData]="appState.state === 'LocalLoading'"
    ></app-skeleton>
    <div
      style="display: flex; justify-content: center !important"
      class="clr-col"
    >
      <button
        style="margin-top: 1rem"
        (click)="handleLoadMoreAssets()"
        class="btn btn-primary-outline"
      >
        LOAD MORE
      </button>
    </div>
  </div>
  <app-snackbar
    *ngIf="
      appState.state === 'HttpLoading' && this.totalLocalAssetsInCatagroy === 0
    "
    [showSnackbar]="appState.state === 'HttpLoading'"
    message="Bitte warten Sie, bis die Assets vollständig geladen sind
    bevor sie benutzt werden können"
  ></app-snackbar>
</div>

<!--THIS CODE IS NOT USED BUT KEPT IN CASE BEEN REQUIRED AGAIN-->
<clr-modal [(clrModalOpen)]="openEditModal">
  <h3 class="modal-title">Asset bearbeiten</h3>
  <div class="modal-body">
    <p>Ausgewähltes Asset: {{ selectedAsset?.title }}</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="openEditModal = false; selectedAsset = null"
    >
      Abbrechen
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="openEditModal = false; selectedAsset = null"
    >
      Speichern
    </button>
  </div>
</clr-modal>
