import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  imports: [NgIf],
  styleUrls: ['./snackbar.component.css'],
  standalone: true,
})
export class SnackbarComponent implements OnInit {
  @Input() showSnackbar: boolean = false;
  @Input() message: string = '';

  closeSnackbar() {
    this.showSnackbar = false;
  }
  constructor() {}
  ngOnInit() {
    if (this.showSnackbar === true) {
      setTimeout(() => {
        this.showSnackbar = false;
      }, 3500);
    }
  }
}
