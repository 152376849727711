import { Injectable } from '@angular/core';
import { Asset } from '../interfaces/asset';
import { Category } from '../interfaces/category';

import { LoaderService, LoaderState } from './loader.service';

export interface TransactionInfo {
  transaction: IDBTransaction;
  description?: string;
  startTime: number;
  categoryNumber?: number;
}
@Injectable({
  providedIn: 'root',
})
/**
 * Service to handle all the database operations
 * When Schema is updated, the version number should be increased
 */
export class DatabaseService {
  private db!: IDBDatabase;
  //IMPORTANT: Update this number when the schema is updated
  //So If New ObjectStores are added, or existing ones are updated
  private currentDBVersion = 2;
  constructor(private loaderService: LoaderService) {
    this.openDatabase();
  }

  private openDatabase(): void {
    const request = indexedDB.open('assets_db', this.currentDBVersion);

    request.onerror = (event) => {
      const request = event.target as IDBOpenDBRequest;
      console.error('Error opening database:', request.error);
    };

    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      this.db = (event.target as IDBOpenDBRequest).result;
      if (event.oldVersion === 0) {
        this.createObjectStores(this.db);
      } else {
        this.updateObjectStores(this.db);
      }
    };

    request.onsuccess = (event) => {
      this.db = (event.target as IDBOpenDBRequest).result;

      console.log('Database opened successfully');
    };
  }
  private updateObjectStores(db: IDBDatabase) {
    const objectStoreNames = Array.from(db.objectStoreNames);
    for (const storeName of objectStoreNames) {
      db.deleteObjectStore(storeName);
    }
    this.createObjectStores(db);
  }

  getDatabase(): Promise<IDBDatabase> {
    let id = this.loaderService.show(
      { message: 'Lokale Verbindung wird aufgebaut...' },
      LoaderState.LocalLoading
    );
    console.log('GETTING DB INSTANCE!');
    return new Promise((resolve, reject) => {
      if (this.db) {
        this.loaderService.hide(id);
        resolve(this.db);
      } else {
        const request = indexedDB.open('assets_db', this.currentDBVersion);
        request.onsuccess = (event: Event) => {
          this.db = (event.target as IDBRequest<IDBDatabase>).result;
          this.loaderService.hide(id);
          console.log('RESOLVED REQUEST');
          resolve(this.db);
        };
        request.onerror = (event: Event) => {
          console.error(
            'Error opening IndexedDB:',
            (event.target as IDBRequest<IDBDatabase>).error
          );
          this.loaderService.hide(id);
          reject((event.target as IDBRequest<IDBDatabase>).error);
        };
      }
    });
  }
  private createObjectStores(db: IDBDatabase) {
    const metaDbDataStore = db.createObjectStore('meta', {
      keyPath: 'id',
      autoIncrement: true,
    });
    metaDbDataStore.createIndex('created_at', 'created_at', {
      unique: true,
    });
    const initialMetaData: {
      createdAt: number;
    } = {
      createdAt: Date.now(),
    };
    metaDbDataStore.add(initialMetaData);

    const categorieStore = db.createObjectStore('categories', {
      keyPath: 'id',
      autoIncrement: true,
    });
    /**
     * categories Table
     */
    categorieStore.createIndex('name', 'name', { unique: false });
    categorieStore.createIndex('icon', 'icon', { unique: false });
    categorieStore.createIndex('visible', 'visible', { unique: false });
    categorieStore.createIndex('row_number', 'row_number', {
      unique: false,
    });
    categorieStore.createIndex('parent', 'parent', { unique: false });
    categorieStore.createIndex('filterable', 'filterable', { unique: false });

    const assetsStore = db.createObjectStore('assets', {
      keyPath: 'id',
      autoIncrement: true,
    });
    /**
     * Assets Table
     */
    assetsStore.createIndex('title', 'title', { unique: false });
    assetsStore.createIndex('description', 'description', { unique: false });
    assetsStore.createIndex('type', 'type', { unique: false });
    assetsStore.createIndex('encoded_data', 'encoded_data', {
      unique: false,
    });
    assetsStore.createIndex('thumbnail', 'thumbnail', { unique: false });
    assetsStore.createIndex('category', 'category', { unique: false });
    assetsStore.createIndex('childCategory', 'childCategory', {
      unique: false,
    });
    assetsStore.createIndex('category_childCategory', [
      'category',
      'childCategory',
    ]);
    assetsStore.createIndex('created_at', 'created_at', { unique: false });
    assetsStore.createIndex('to_delete', 'to_delete', { unique: false });
    const categortyUpdatedDate = db.createObjectStore('category_last_updated', {
      keyPath: 'id',
      autoIncrement: true,
    });
    categortyUpdatedDate.createIndex('last_updated', 'last_updated', {
      unique: false,
    });
    categortyUpdatedDate.createIndex('category_id', 'category_id', {
      unique: true,
    });
  }
  getMetaData(): Promise<{
    createdAt: number;
  }> {
    return new Promise<{
      createdAt: number;
    }>((resolve, reject) => {
      const transaction = this.db.transaction(['meta'], 'readonly');
      const objectStore = transaction.objectStore('meta');
      const request = objectStore.get(1);
      request.onsuccess = () => {
        resolve(
          request.result as {
            createdAt: number;
          }
        );
      };
      request.onerror = () => {
        reject(new Error(`Failed to get metadata: ${request.error?.message}`));
      };
    });
  }
  setCreatedAt(createdAt: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.db.transaction(['meta'], 'readwrite');
      const objectStore = transaction.objectStore('meta');
      const request = objectStore.get(1); // Get the existing metadata

      request.onsuccess = () => {
        const existingMetaData = request.result as
          | { createdAt: number }
          | undefined;

        if (existingMetaData) {
          // Update the existing metadata with the new createdAt value
          existingMetaData.createdAt = createdAt;
          const updateRequest = objectStore.put(existingMetaData);

          updateRequest.onsuccess = () => resolve();
          updateRequest.onerror = () => {
            reject(
              new Error(
                `Failed to update metadata: ${updateRequest.error?.message}`
              )
            );
          };
        } else {
          reject(new Error('Metadata not found'));
        }
      };

      request.onerror = () => {
        reject(new Error(`Failed to get metadata: ${request.error?.message}`));
      };
    });
  }
  getObjectStoreCount(db: IDBDatabase, storeName: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, 'readonly');
      const objectStore = transaction.objectStore(storeName);
      const countRequest = objectStore.count();
      countRequest.onsuccess = () => {
        resolve(countRequest.result);
      };
      countRequest.onerror = () => {
        reject(countRequest.error);
      };
    });
  }
  addRecords(
    objectStoreName: string,
    records: Asset[] | Category[]
  ): Promise<boolean> {
    console.info('Started Transaction To Add New Records');
    let id = this.loaderService.show(
      { message: 'Lokale Daten werden synchronisiert...' },
      LoaderState.TransactionStarted
    );

    return new Promise(async (resolve, reject) => {
      const transaction = this.db.transaction([objectStoreName], 'readwrite');
      const store = transaction.objectStore(objectStoreName);

      let hasError = false;

      transaction.oncomplete = async () => {
        if (objectStoreName !== 'categories') {
          await this.addCategoryUpdatedDate(records as Asset[], id);
        }
        this.loaderService.hide(id);
        resolve(true);
        console.info(`Transaction completed.`);
      };

      transaction.onerror = (event) => {
        const request = event.target as IDBRequest;
        if (!hasError) {
          console.error('Transaction failed:', request.error);
          this.loaderService.hide(id);
        }
        reject(request.error);
      };

      for (let record of records) {
        if (objectStoreName === 'categories') {
          record = { ...record, category_last_updated: new Date() };
        }
        try {
          const recordExist = await new Promise((resolve, reject) => {
            const recordExistReq = store.get(record.id);
            recordExistReq.onsuccess = (e) => {
              resolve((e.target as IDBRequest).result);
            };
            recordExistReq.onerror = (e) => {
              reject(e);
            };
          });

          if (recordExist) {
            console.error('DoubleEntryError: Record Already Exist Locally');
            this.loaderService.hide(id);
          } else {
            const request = store.add(record);
            request.onerror = (event: Event) => {
              hasError = true;
              const request = event.target as IDBRequest;
              console.error(
                'Error adding record:',
                (request.error as Error).message
              );
              this.loaderService.hide(id);
              transaction.abort();
              reject(request.error);
            };
          }
        } catch (error) {
          hasError = true;
          console.error('Error checking for existing record:', error);
          transaction.abort();
          this.loaderService.hide(id);
          reject(error);
        }
      }
    });
  }
  async addCategoryUpdatedDate(
    records: Asset[],
    loaderId: string
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (records.length > 0) {
        let hasError = false;
        const updateStoreTransaction = this.db.transaction(
          ['category_last_updated'],
          'readwrite'
        );
        updateStoreTransaction.onerror = (event) => {
          const request = event.target as IDBRequest;
          if (!hasError) {
            console.error('Transaction failed:', request.error);
            this.loaderService.hide(loaderId);
          }
          reject(request.error);
        };
        const updateStore = updateStoreTransaction.objectStore(
          'category_last_updated'
        );
        try {
          const recordExist = await new Promise((resolve, reject) => {
            const recordExistReq = updateStore
              .index('category_id')
              .get(Number((records as Asset[])[0].category));
            recordExistReq.onsuccess = (e) => {
              resolve((e.target as IDBRequest).result);
            };
            recordExistReq.onerror = (e) => {
              reject(e);
            };
          });

          if (recordExist) {
            Object.assign(recordExist, {
              ...recordExist,
              last_updated: new Date(),
            });
            const updateRequest = updateStore.put(recordExist);
            updateRequest.onsuccess = () => resolve(true);
            updateRequest.onerror = (event: Event) => {
              hasError = true;
              const request = event.target as IDBRequest;
              console.error(
                'Error adding record:',
                (request.error as Error).message
              );
              updateStoreTransaction.abort();
              this.loaderService.hide(loaderId);
              reject(request.error);
            };
          } else {
            const addRecordReq = updateStore.add({
              category_id: (records as Asset[])[0].category,
              last_updated: new Date(),
            });
            addRecordReq.onsuccess = () => resolve(true);
            addRecordReq.onerror = (event: Event) => {
              hasError = true;
              const request = event.target as IDBRequest;
              console.error(
                'Error adding record:',
                (request.error as Error).message
              );
              this.loaderService.hide(loaderId);
              updateStoreTransaction.abort();
              reject(request.error);
            };
          }
        } catch (error) {
          hasError = true;
          console.error('Error checking for existing record:', error);
          this.loaderService.hide(loaderId);
          updateStoreTransaction.abort();
          reject(error);
        }
      }
    });
  }
  async getCategoryLastUpdatedDate(categoryId: number): Promise<Date | null> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(['category_last_updated']);
      const objectStore = transaction.objectStore('category_last_updated');
      const index = objectStore.index('category_id');
      const request = index.get(Number(categoryId));
      request.onsuccess = (event: Event) => {
        const record = (event.target as IDBRequest).result;
        if (record) {
          resolve(record.last_updated);
        } else {
          resolve(null);
        }
      };
      request.onerror = (event: Event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }
  async getLastUpdatedRecords(): Promise<
    Array<{ category_id: number; id: number; last_updated: Date }>
  > {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(['category_last_updated']);
      const objectStore = transaction.objectStore('category_last_updated');
      const request = objectStore.getAll();
      request.onsuccess = (event: Event) => {
        const records = (event.target as IDBRequest).result;
        resolve(records);
      };
      request.onerror = (event: Event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }
  async getAssetCountByCategory(
    category: number,
    childCategory?: number
  ): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      const transaction = this.db.transaction(['assets']);
      const objectStore = transaction.objectStore('assets');

      let countRequest: IDBRequest;

      if (childCategory !== undefined) {
        const index = objectStore.index('category_childCategory');
        const keyRange = IDBKeyRange.only([
          Number(category),
          Number(childCategory),
        ]);
        countRequest = index.count(keyRange);
      } else {
        const index = objectStore.index('category');
        countRequest = index.count(Number(category));
      }

      countRequest.onsuccess = (event: Event) => {
        resolve((event.target as IDBRequest).result);
      };

      countRequest.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }
  async getDataFromIndexDbByCategory(
    category: number,
    chunkSize = 500,
    startIndex = 0,
    getAll?: boolean
  ): Promise<Asset[]> {
    console.log('Get local assets');
    let id = this.loaderService.show(
      { message: 'Lokale Daten werden abgerufen...' },
      LoaderState.LocalLoading
    );
    return new Promise<Asset[]>(async (resolve, reject) => {
      try {
        const count = await this.getAssetCountByCategory(category);
        const transaction = this.db.transaction(['assets']);
        const objectStore = transaction.objectStore('assets');
        const index = objectStore.index('category');
        if (count > chunkSize && !getAll) {
          console.log('DATA TOO BIG, FETCHIN IN CHUNKS');
          const range = IDBKeyRange.only(Number(category));
          const request = index.openCursor(range);
          let results: Asset[] = [];
          let fetchedCount = 0;
          request.onsuccess = (event) => {
            const cursor = (event.target as IDBRequest).result;
            if (cursor) {
              //Check if its the first fetch or first chunk already loaded
              //if its not first fetch, then advance to the location where
              //fetchin data stopped by last iteration
              if (startIndex > 0) {
                if (startIndex < count) {
                  cursor.advance(startIndex);
                } else {
                  //this avoid jumping out of bound for cursor
                  cursor.advance(count - 1);
                }
                // Move the cursor to the starting index
                startIndex = 0; // Reset startIndex for subsequent records
              } else if (fetchedCount < chunkSize) {
                // this section cumulate data till chunkSize being reached
                results.push(cursor.value);
                fetchedCount++;
                cursor.continue();
              } else {
                this.loaderService.hide(id);
                resolve(results);
              }
            } else {
              // Reached the end of the cursor
              this.loaderService.hide(id);
              resolve(results);
            }
          };
          request.onerror = (event) => {
            this.loaderService.hide(id);
            reject((event.target as IDBRequest).error);
          };
        } else {
          const getAllRequest = index.getAll(Number(category));
          getAllRequest.onsuccess = (event: Event) => {
            this.loaderService.hide(id);
            resolve((event.target as IDBRequest).result);
          };
          getAllRequest.onerror = (event) => {
            this.loaderService.hide(id);
            reject((event.target as IDBRequest).error);
          };
        }
      } catch (error) {
        this.loaderService.hide(id);
        reject(error);
      }
    });
  }

  async getAssetsByCategoryAndChildCategorie(
    categoryId: number,
    childCategoryId: number
  ): Promise<Asset[]> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(['assets'], 'readonly');
      const objectStore = transaction.objectStore('assets');
      const index = objectStore.index('category_childCategory');
      const range = IDBKeyRange.only([
        Number(categoryId),
        Number(childCategoryId),
      ]);

      const request = index.openCursor(range);
      const matchingAssets: Asset[] = [];
      request.onsuccess = (event: Event) => {
        const cursor = (event.target as IDBRequest<IDBCursorWithValue | null>)
          .result;
        if (cursor) {
          matchingAssets.push(cursor.value);
          cursor.continue();
        } else {
          resolve(matchingAssets);
        }
      };

      request.onerror = (event: Event) => {
        console.error(
          'Error opening cursor:',
          (event.target as IDBRequest).error
        );
        reject((event.target as IDBRequest).error);
      };
    });
  }
  /**
   *
   * @param category catrogry of asset to update
   * @returns resolved Promise with boolean state as Value
   */
  async deleteAssetsByCategory(category: number): Promise<boolean> {
    let id = this.loaderService.show(
      { message: 'Lokale Daten werden synchronisiert...' },
      LoaderState.TransactionStarted
    );
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(['assets'], 'readwrite');
      const objectStore = transaction.objectStore('assets');
      const index = objectStore.index('category');
      const cursorRequest = index.openCursor(Number(category));
      let updated = false;
      cursorRequest.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor) {
          objectStore.delete(cursor.primaryKey);
          cursor.continue();
        } else {
          console.log('Deleted Old Data succefully!');
          updated = true;
          this.loaderService.hide(id);
          resolve(updated);
          transaction.commit();
        }
      };
      cursorRequest.onerror = (event) => {
        const error = (event.target as IDBRequest).error;
        console.error('Error opening cursor:', error);
        this.loaderService.hide(id);
        reject(new Error('Error opening cursor: ' + error?.message));
      };
    });
  }
  getAllCategoriesRecordsLocally(): Promise<Category[]> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(['categories'], 'readonly');
      const store = transaction.objectStore('categories');
      const request = store.getAll();
      request.onsuccess = (event: Event) => {
        const request = event.target as IDBRequest;
        resolve(request.result);
      };
      request.onerror = (event: Event) => {
        const request = event.target as IDBRequest;
        reject(request.error);
      };
    });
  }
  async deleteDatabase(dbName: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const request = window.indexedDB.deleteDatabase(dbName);

      request.onsuccess = () => {
        console.log(`Database "${dbName}" deleted successfully.`);
        resolve(true);
      };

      request.onerror = (event: Event) => {
        const target = event.target as IDBRequest;
        console.error(`Error deleting database "${dbName}":`, target.error);
        reject(target.error);
      };

      request.onblocked = () => {
        console.warn(
          `Database "${dbName}" is blocked and cannot be deleted right now.`
        );
        reject(new Error(`Database "${dbName}" is blocked.`));
      };
    });
  }
  async clearObjectStore(storeName: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.db.transaction([storeName], 'readwrite');
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.clear();

      request.onsuccess = () => {
        console.log(`Object store "${storeName}" cleared successfully.`);
        resolve();
      };

      request.onerror = (event) => {
        const target = event.target as IDBRequest;
        console.error(
          `Error clearing object store "${storeName}":`,
          target.error
        );
        reject(target.error);
      };
    });
  }
  //-------------------------------------------------------FOLLOWING NOT USED YET---------------------------------------------------

  updateRecord(record: unknown): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction('object-store-name', 'readwrite');
      const store = transaction.objectStore('object-store-name');
      const request = store.put(record);

      request.onsuccess = (event: Event) => {
        const request = event.target as IDBRequest;
        resolve(request.result);
      };

      request.onerror = (event: Event) => {
        const request = event.target as IDBRequest;
        reject(request.error);
      };
    });
  }

  getAllRecords(): Promise<unknown[]> {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction('object-store-name', 'readonly');
      const store = transaction.objectStore('object-store-name');
      const request = store.openCursor();
      const records: unknown[] = [];

      request.onsuccess = (event: Event) => {
        const cursor = (event.target as IDBRequest).result;
        if (cursor) {
          records.push(cursor.value);
          cursor.continue();
        } else {
          resolve(records);
        }
      };

      request.onerror = (event: Event) => {
        const request = event.target as IDBRequest;
        reject(request.error);
      };
    });
  }
}
