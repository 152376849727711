import { NgForOf, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  imports: [
    NgForOf,
    NgIf
  ],
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.css'],
  standalone:true,
})
export class SkeletonComponent {
  @Input() readingData = false;

}
